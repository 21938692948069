<template>
  <div>
    <b-container
      fluid
    >
      <b-row
        class="mb-4"
      >
        <b-col
          lg="2"
        >
          <b-button
            v-b-toggle.sidebar-1
            variant="outline-secondary"
            class="m-1 d-block d-lg-none btn-block mb-4"
          >
            Filter
            <b-icon
              icon="gear"
              aria-hidden="true"
            />
          </b-button>
          <b-sidebar
            id="sidebar-1"
            :title="'Showing ' + droneCount + ' drones'"
            shadow
            backdrop
          >
            <div class="px-3 py-2">
              <b-form-group
                label="Price range"
                align="left"
              >
                <b-form-select
                  name="min-price-opts"
                  v-model="filterByMinPrice"
                  :options="minPriceOptions"
                  @change="minPriceChange"
                  class="w-100 mb-2"
                />
                <b-form-select
                  name="max-price-opts"
                  v-model="filterByMaxPrice"
                  :options="maxPriceOptions"
                  @change="maxPriceChange"
                  class="w-100 mb-2"
                />
              </b-form-group>
              <b-form-group
                label="Category"
                align="left"
              >
                <b-form-radio-group
                  id="btn-radios-2"
                  name="type"
                  :options="filterTypes"
                  v-model="filterByType"
                  button-variant="light"
                  buttons
                  stacked
                  class="w-100 mb-2"
                />
              </b-form-group>

              <b-form-group
                label="Video quality"
                align="left"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  name="radios-btn-default"
                  :options="options"
                  v-model="filterByVideo"
                  button-variant="light"
                  buttons
                  stacked
                  class="w-100 mb-2"
                />
              </b-form-group>
              <b-form-group
                label="Require GPS?"
                align="left"
              >
                <b-form-radio-group
                  name="gps"
                  :options="gpsOptions"
                  v-model="filterIsGPS"
                  button-variant="light"
                  buttons
                  class="w-100 mb-2"
                />
              </b-form-group>
              <h5 class="text-center">
                <a
                  href="#"
                  class="reset-filters"
                  @click="resetFilters()"
                >
                  <small>Reset all filters</small>
                </a>
              </h5>
            </div>
          </b-sidebar>
          <!-- Desktop filtering -->
          <div class="d-none d-lg-block">
            <h5 class="text-left">
              Filters
              <a
                href="#"
                class="float-right reset-filters"
                @click="resetFilters()"
              >
                <small>Reset all</small>
              </a>
            </h5>
            <hr>
            <b-form-group
              label="Price range"
              align="left"
            >
              <b-form-select
                name="min-price-opts"
                v-model="filterByMinPrice"
                :options="minPriceOptions"
                @change="minPriceChange"
                class="w-100 mb-2"
              />
              <b-form-select
                name="max-price-opts"
                v-model="filterByMaxPrice"
                :options="maxPriceOptions"
                @change="maxPriceChange"
                class="w-100 mb-2"
              />
            </b-form-group>
            <hr>
            <b-form-group
              label="Category"
              align="left"
            >
              <b-form-radio-group
                id="btn-radios-2"
                name="type"
                :options="filterTypes"
                v-model="filterByType"
                button-variant="light"
                buttons
                stacked
                class="w-100 mb-2"
              />
            </b-form-group>
            <hr>
            <b-form-group
              label="Video quality"
              align="left"
            >
              <b-form-radio-group
                id="btn-radios-1"
                name="radios-btn-default"
                :options="options"
                v-model="filterByVideo"
                button-variant="light"
                buttons
                stacked
                class="w-100 mb-2"
              />
            </b-form-group>
            <hr>
            <b-form-group
              label="Require GPS?"
              align="left"
            >
              <b-form-radio-group
                name="gps"
                :options="gpsOptions"
                v-model="filterIsGPS"
                button-variant="light"
                buttons
                class="w-100 mb-2"
              />
            </b-form-group>
          </div>
          <p class="d-none d-lg-block">
            <small>
              <strong>Note:</strong> As an Amazon Associate, we may earn commissions from
              qualifying purchases from Amazon.com or Amazon.co.uk when following latest price links from this site.
              <a href="/about">More info</a>
            </small>
          </p>
        </b-col>
        <b-col
          lg="10"
          class="pl-4"
        >
          <b-row>
            <b-col>
              <h4
                class="text-left"
                v-if="!loadingState"
              >
                Showing {{ droneCount }} drones
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <div
              v-if="loadingState"
              class="mx-auto"
            >
              <b-spinner />
            </div>
            <DroneCard
              v-else
              v-for="drone in filterList"
              :key="drone.id"
              :drone="drone"
              :image-host="imageHost"
              @childToParent="onChildClick"
            />
          </b-row>
        </b-col>
        <b-row class="p-4">
          <b-col>
            <p class="d-block d-lg-none">
              <small>
                <strong>Note:</strong> As an Amazon Associate, we may earn commissions from
                qualifying purchases from Amazon.com or Amazon.co.uk when following latest price links from this site.
                <a href="/about">More info</a>
              </small>
            </p>
          </b-col>
        </b-row>
        <Modal
          :selected-drone="selectedDrone"
          :image-host="imageHost"
        />
        <ModalCompare
          :selected-drone="selectedDrone"
          :drones="drones"
        />
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
// TODO: Change this alias?
import DronesService from '../services/DronesService';
// import FilterOptions from '../components/FilterOptions.vue';
import DroneCard from '../components/DroneCard.vue';
import Modal from '../components/Modal.vue';
import ModalCompare from '../components/ModalCompare.vue';

export default {
  name: 'Home',
  components: {
    DroneCard,
    Modal,
    ModalCompare,
  },
  props: {
    backdrop: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      imageHost: process.env.VUE_APP_DRONEDATA_API_HOST,
      drones: [],
      loadingState: false,
      selectedDrone: {},
      droneCount: '',
      filterTypes: [],
      filterByType: 'All',
      filterIsGPS: false,
      filterByVideo: 0,
      filterByMinPrice: 0,
      filterByMaxPrice: 2500,
      gpsOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      minPriceOptions: [
        { text: 'No Min', value: '0' },
      ],
      maxPriceOptions: [
        { text: 'No Max', value: '2500' },
      ],
      options: [
        { text: 'All', value: '0' },
        { text: '720p', value: '720' },
        { text: '1080px', value: '1080' },
        { text: '4k', value: '3840' },
      ],
    };
  },
  created() {
    DronesService.getDrones()
      .then((response) => {
        this.drones = response.data;
        const filterArr = ['All'];
        // TODO need to sort this bringing back of names for modal compare select
        Object.keys(this.drones).forEach((prop) => {
          Object.keys(this.drones[prop].types).forEach((propt) => {
            filterArr.push(this.drones[prop].types[propt]);
          });
        });
        const uniqueTypes = [...new Set(filterArr)];
        this.filterTypes = uniqueTypes;
      })
      .catch((error) => {
        console.log(`There was an issue: ${error.response}`);
      });
    // eslint-disable-next-line no-plusplus
    for (let step = 100; step <= 2500; step += 100) {
      this.minPriceOptions.push({ text: `$${step}`, value: step });
      this.maxPriceOptions.push({ text: `$${step}`, value: step });
    }
  },
  methods: {
    // Triggered when `childToParent` event is emitted by the child.
    onChildClick(value) {
      this.selectedDrone = value;
    },
    resetFilters() {
      this.filterByType = 'All';
      this.filterIsGPS = false;
      this.filterByVideo = 0;
      this.filterByMinPrice = 0;
      this.filterByMaxPrice = 2500;
    },
    // TODO probably neater way to do this in one method?
    minPriceChange(val) {
      console.log(val);
      this.maxPriceOptions = [{ text: 'No Max', value: '1500' }];
      for (let step = parseInt(val, 10); step <= 1500; step += 100) {
        this.maxPriceOptions.push({ text: `$${step}`, value: step });
      }
    },
    // TODO probably neater way to do this in one method?
    maxPriceChange(val) {
      this.minPriceOptions = [{ text: 'No Min', value: '0' }];
      for (let step = 0; step <= parseInt(val, 10); step += 100) {
        this.minPriceOptions.push({ text: `$${step}`, value: step });
      }
    },
  },
  computed: {
    filterList() {
      // eslint-disable-next-line arrow-body-style
      const filterList = this.drones.filter((drone) => {
        return (
          // eslint-disable-next-line no-nested-ternary
          drone.price >= this.filterByMinPrice
          && drone.price <= this.filterByMaxPrice
          && drone.video_quality >= this.filterByVideo
          && (this.filterIsGPS ? drone.gps === true : true)
          && (this.filterByType === 'All' ? true
            : drone.types.some((r) => this.filterByType.indexOf(r) >= 0))
        );
      });
      return filterList;
    },
  },
  watch: {
    filterList(val) {
      this.loadingState = true;
      this.droneCount = val.length;
      setTimeout(() => {
        this.loadingState = false;
      }, 150);
    },
  },
};
</script>

<style>
  body {
    background-color:#f9fbfc;
  }
  a {
    color:#00b289;
    font-weight: 500;
  }

  label.btn.btn-light.active, label.btn.btn-light:hover{
    background-color: rgba(0,178,148,0.1)!important;
    border-color:transparent!important;
  }

  #filter-collapse.show{
    display: block!important;
  }

  .btn-primary {
    background-color:#00b289;
    color:#fff;
    border-color:#00b266;
    font-weight: 500;
  }

  .btn-secondary:hover {
    background-color:#00b289;
    color:#fff;
  }

  .modal-body img{
    max-width:100%;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .col-form-label {
    font-weight: 500;
  }

  label.btn {
    border-radius:0;
  }
</style>
