<template>
  <div>
    <CompareTable
      :drones="drones"
      :drone1="drone1"
      :drone2="drone2"
      :image-host="imageHost"
    />
    <br>
  </div>
</template>

<script>
import DronesService from '../services/DronesService';
import CompareTable from '../components/CompareTable.vue';

export default {
  name: 'Compare',
  components: {
    CompareTable,
  },
  data() {
    return {
      drones: [],
      drone1: {},
      drone2: {},
      dronesToCompare: {},
      imageHost: process.env.VUE_APP_DRONEDATA_API_HOST,
    };
  },
  metaInfo() {
    return {
      title: `Compare ${this.drone1.manufacturer} ${this.drone1.model} vs 
        ${this.drone2.manufacturer} ${this.drone2.model}`,
    };
  },
  created() {
    this.getDronesToCompare();
  },
  methods: {
    getDronesToCompare() {
      // This gets the path name contain drone models
      this.dronesToCompare = this.$route.params.id;
      const [droneSlug1, droneSlug2] = this.dronesToCompare.split('-vs-');
      this.filterDrones(droneSlug1, droneSlug2);
    },

    // Filter out drones to compare
    filterDrones(droneSlug1, droneSlug2) {
      DronesService.getDrones()
        .then((response) => {
          this.drones = response.data;
          // eslint-disable-next-line prefer-destructuring
          this.drone1 = response.data.filter((d) => d.slug === droneSlug1)[0];
          // eslint-disable-next-line prefer-destructuring
          this.drone2 = response.data.filter((d) => d.slug === droneSlug2)[0];
        })
        .catch((error) => {
          console.log(`There was an issue: ${error.response}`);
        });
    },
  },
  watch: {
    $route() {
      console.log('changed');
      this.getDronesToCompare();
      // filterDrones(droneSlug1, droneSlug2);
    },
  },
};

</script>
