import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Compare from '../views/Compare.vue';
import Markdown from '../views/Markdown.vue';

// TODO: Do we really have to create one of these for every single article?
import MdArticles from '../../../content/articles.md';
import MdBestCameraDrones from '../../../content/best-camera-drones.md';
import MdBestDronesForBeginners from '../../../content/best-drones-for-beginners.md';
import MdBestDronesForKids from '../../../content/best-drones-for-kids.md';
import MdDroneRegistrationSchemeUk from '../../../content/drone-registration-scheme-uk.md';
import MdGlossary from '../../../content/glossary.md';
import MdPrivacyPolicy from '../../../content/privacy-policy.md';
import MdAbout from '../../../content/about.md';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/compare/:id',
    name: 'Compare',
    component: Compare,
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Markdown,
    props: {
      content: MdArticles,
    },
  },
  {
    path: '/articles/best-drones-for-beginners-2019',
    component: Markdown,
    props: {
      content: MdBestDronesForBeginners,
    },
  },
  {
    path: '/articles/best-drones-for-kids',
    component: Markdown,
    props: {
      content: MdBestDronesForKids,
    },
  },
  {
    path: '/articles/best-camera-drones',
    component: Markdown,
    props: {
      content: MdBestCameraDrones,
    },
  },
  {
    path: '/articles/drone-registration-scheme-uk',
    component: Markdown,
    props: {
      content: MdDroneRegistrationSchemeUk,
    },
  },
  {
    path: '/glossary',
    component: Markdown,
    props: {
      content: MdGlossary,
    },
  },
  {
    path: '/about',
    component: Markdown,
    props: {
      content: MdAbout,
    },
  },
  {
    path: '/privacy-policy',
    component: Markdown,
    props: {
      content: MdPrivacyPolicy,
    },
  },
  // TODO: 404
  // {
  //   path: '*',
  //   name: '404 - Not found',
  //   component: NotFound,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
