<template>
  <b-col
    lg="3"
    md="4"
    sm="12"
    class="mb-4"
  >
    <b-card
      lg="4"
      class="h-100 drone-card"
      footer-tag="footer"
    >
      <b-card-img
        v-b-modal.modal-1
        :src="imageHost + '/assets/images/drones/' + drone.image_base + '.small.jpg'"
        @click="sendInfo(drone)"
      />
      <h4 class="mt-0 text-left">
        {{ drone.manufacturer }} - {{ drone.model }}
      </h4>
      <p class="text-left d-lg-blck">
        <b-badge
          class="mr-1"
          v-b-tooltip.hover
          :title="'Max flight time of this drone is ' + drone.flight_time + 'mins'"
        >
          <b-icon-battery-half /> {{ drone.flight_time }}mins
        </b-badge>
        <b-badge
          class="mr-1"
          v-b-tooltip.hover
          :title="'Max range of this drone is ' + drone.range + 'm'"
        >
          <b-icon-wifi />
          {{ drone.range | humanRange }}
        </b-badge>
        <b-badge
          class="mr-1"
          v-b-tooltip.hover
          :title="'Camera on this drone can  ' + drone.camera + 'mp'"
        >
          <b-icon-camera /> {{ drone.camera | humanCameraQuality }}
        </b-badge>
        <b-badge
          class="mr-1"
          v-b-tooltip.hover
          :title="'Video on this drone can record at  ' +
            $options.filters.humanVideoQuality(drone.video_quality)"
        >
          <b-icon-camera-video /> {{ drone.video_quality | humanVideoQuality }}
        </b-badge>
      </p>
      <h5 class="text-left">
        ${{ drone.price }}
      </h5>
      <b-link
        v-b-modal.modal-compare
        @click="sendInfo(drone)"
      >
        Compare with...
      </b-link>
      <template v-slot:footer>
        <b-button
          variant="outline-secondary"
          class="btn-block"
          v-b-modal.modal-1
          @click="sendInfo(drone)"
        >
          More info
        </b-button>
        <b-button
          v-if="drone.has_affiliate"
          class="btn-block btn-primary"
          :href="'/buy/'+ drone.id"
          target="_blank"
        >
          Check latest price
        </b-button>
        <b-button
          v-else
          disabled
          class="btn-block btn-secondary"
        >
          No buy link yet
        </b-button>
      </template>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: {
    imageHost: {
      type: String,
      default() {
        return '';
      },
    },
    droneCount: {
      type: String,
      default() {
        return '';
      },
    },
    drone: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    sendInfo(drone) {
      this.$emit('childToParent', drone);
    },
  },
};
</script>

<style>

.drone-card img{
  cursor: pointer;
}

.drone-card img:focus{
    outline: none;
}

</style>
