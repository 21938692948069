<template>
  <div>
    <b-modal
      id="modal-compare"
      size="md"
      cancel-hide
      hide-footer
      :title="'Compare ' + selectedDrone.manufacturer + ' ' + selectedDrone.model + ' with'"
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-form-select
              v-model="selected"
              @change="onChange"
              name="select-drone"
            >
              <b-form-select-option
                value="nothing"
              >
                Please select...
              </b-form-select-option>
              <b-form-select-option
                v-for="drone in dronesLeftToCompare"
                :key="drone.id"
                :value="drone.slug"
              >
                {{ drone.manufacturer }} - {{ drone.model }}
              </b-form-select-option>
            </b-form-select>
            <p
              v-for="error in errors"
              :key="error.id"
              class="text-danger"
            >
              {{ error }}
            </p>
            <b-button
              size="md"
              class="my-2"
              block
              variant="primary"
              @click="compareDrones(selectedDrone.slug, droneToCompare)"
            >
              Compare them &raquo;
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: 'nothing',
      droneToCompare: '',
      errors: [],
    };
  },
  props: {
    selectedDrone: {
      type: Object,
      default() {
        return {};
      },
    },
    drones: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    compareDrones(selected, droneToCompare) {
      // always reset errors back to empty
      this.errors = [];
      // Check they've not seleted "Please select a drone" option
      if (droneToCompare !== '') {
        this.$router.push(`/compare/${selected}-vs-${droneToCompare}`);
      } else {
        this.errors.push('Please select a drone');
      }
    },
    onChange(value) {
      this.droneToCompare = value;
    },
  },
  computed: {
    // This filters out the drone they have selected to compare so you cant compare the same one
    dronesLeftToCompare() {
      return this.drones.filter((drone) => drone.slug !== this.selectedDrone.slug);
    },
  },
};
</script>
