import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueShowdown from 'vue-showdown';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Meta from 'vue-meta';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';

Vue.use(Meta);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueGtag, {
  config: { id: 'UA-115028450-4' },
  bootstrap: false,
});

Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: 'github',
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: false,
  },
});

Vue.config.productionTip = false;

// Format camera to readable format
Vue.filter('humanCameraQuality', (mp) => {
  if (mp < 1) {
    return 'n/a';
  }
  return `${mp}MP`;
});

// Format meters into human display
Vue.filter('humanRange', (metres) => {
  if (metres < 1000) {
    return `${metres}m`;
  }
  const km = metres / 1000;
  return `${km}km`;
});

Vue.filter('humanVideoQuality', (pixels) => {
  if (pixels < 320) {
    return 'n/a';
  } if (pixels < 1080) {
    return '720p';
  } if (pixels >= 1080 && pixels < 3840) {
    return '1080p';
  } if (pixels >= 3840 && pixels < 4800) {
    return '4k';
  } if (pixels >= 4800 && pixels < 5760) {
    return '5k';
  }
  return '5k+';
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
