<template>
  <div>
    <b-container class="container-md">
      <b-row>
        <b-col>
          <h2 class="my-4 text-center">
            {{ drone1.manufacturer }} {{ drone1.model }} VS {{ drone2.manufacturer }} {{ drone2.model }}
          </h2>
          <b-table-simple
            striped
          >
            <b-tbody>
              <b-tr>
                <b-th />
                <b-td>
                  <b-img
                    :src="imageHost + '/assets/images/drones/' + drone1.image_base + '.small.jpg'"
                    fluid
                    width="300"
                  />
                </b-td>
                <b-td>
                  <b-img
                    :src="imageHost + '/assets/images/drones/' + drone2.image_base + '.small.jpg'"
                    fluid
                    width="300"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th />
                <b-td>
                  <b-form-select
                    v-model="selected1"
                    @change="onChange(selected1, selected2)"
                  >
                    <b-form-select-option
                      v-for="drone in firstSelectedDrones"
                      :key="drone.id"
                      :value="drone.slug"
                    >
                      {{ drone.manufacturer }} - {{ drone.model }}
                    </b-form-select-option>
                  </b-form-select>
                </b-td>
                <b-td>
                  <b-form-select
                    v-model="selected2"
                    @change="onChange(selected1, selected2)"
                  >
                    <b-form-select-option
                      v-for="drone in secondSelectedDrones"
                      :key="drone.id"
                      :value="drone.slug"
                    >
                      {{ drone.manufacturer }} - {{ drone.model }}
                    </b-form-select-option>
                  </b-form-select>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Drone
                </b-th>
                <b-td>
                  {{ drone1.manufacturer }} {{ drone1.model }}
                </b-td>
                <b-td>
                  {{ drone2.manufacturer }} {{ drone2.model }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Approx price
                </b-th>
                <b-td>
                  {{ drone1.price }}
                  <b-icon-star-fill
                    v-if="drone1.price < drone2.price"
                    variant="warning"
                  />
                </b-td>
                <b-td>
                  {{ drone2.price }}
                  <b-icon-star-fill
                    v-if="drone2.price < drone1.price"
                    variant="warning"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Release date
                </b-th>
                <b-td v-if="drone1.release_date">
                  {{ drone1.release_date }}
                  <b-icon-star-fill
                    v-if="drone1.release_date > drone2.release_date"
                    variant="warning"
                  />
                </b-td>
                <b-td v-else>
                  Unknown
                </b-td>
                <b-td v-if="drone2.release_date">
                  {{ drone2.release_date }}
                  <b-icon-star-fill
                    v-if="drone2.release_date > drone1.release_date"
                    variant="warning"
                  />
                </b-td>
                <b-td v-else>
                  Unknown
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Flight time
                </b-th>
                <b-td>
                  {{ drone1.flight_time }}
                  <b-icon-star-fill
                    v-if="drone1.flight_time > drone2.flight_time"
                    variant="warning"
                  />
                </b-td>
                <b-td>
                  {{ drone2.flight_time }}
                  <b-icon-star-fill
                    v-if="drone2.flight_time > drone1.flight_time"
                    variant="warning"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Range
                </b-th>
                <b-td>
                  {{ drone1.range | humanRange }}
                  <b-icon-star-fill
                    v-if="drone1.range > drone2.range"
                    variant="warning"
                  />
                </b-td>
                <b-td>
                  {{ drone2.range | humanRange }}
                  <b-icon-star-fill
                    v-if="drone2.range > drone1.range"
                    variant="warning"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Camera
                </b-th>
                <b-td>
                  {{ drone1.camera | humanCameraQuality }}
                  <b-icon-star-fill
                    v-if="drone1.camera > drone2.camera"
                    variant="warning"
                  />
                </b-td>
                <b-td>
                  {{ drone2.camera | humanCameraQuality }}
                  <b-icon-star-fill
                    v-if="drone2.camera > drone1.camera"
                    variant="warning"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  Video
                </b-th>
                <b-td>
                  {{ drone1.video_quality | humanVideoQuality }}
                  <b-icon-star-fill
                    v-if="drone1.video_quality > drone2.video_quality"
                    variant="warning"
                  />
                </b-td>
                <b-td>
                  {{ drone2.video_quality | humanVideoQuality }}
                  <b-icon-star-fill
                    v-if="drone2.video_quality > drone1.video_quality"
                    variant="warning"
                  />
                </b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-left">
                  GPS enabled?
                </b-th>
                <b-td v-if="drone1.gps">
                  Yes
                  <b-icon-star-fill
                    v-if="drone1.gps & !drone2.gps"
                    variant="warning"
                  />
                </b-td>
                <b-td v-else>
                  No
                </b-td>
                <b-td v-if="drone2.gps">
                  Yes
                  <b-icon-star-fill
                    v-if="drone2.gps & !drone1.gps"
                    variant="warning"
                  />
                </b-td>
                <b-td v-else>
                  No
                </b-td>
              </b-tr>
              <b-tr>
                <b-th />
                <b-td>
                  <b-button
                    v-if="drone1.has_affiliate"
                    class="btn-block btn-primary"
                    :href="'/buy/'+ drone1.id"
                  >
                    Check latest price of {{ drone1.model }}
                  </b-button>
                  <b-button
                    v-else
                    class="btn-block btn-secondary"
                  >
                    No buy link yet
                  </b-button>
                </b-td>
                <b-td>
                  <b-button
                    v-if="drone2.has_affiliate"
                    class="btn-block btn-primary"
                    :href="'/buy/'+ drone2.id"
                  >
                    Check latest price of {{ drone2.model }}
                  </b-button>
                  <b-button
                    v-else
                    class="btn-block btn-secondary"
                  >
                    No buy link yet
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected1: '',
      selected2: '',
    };
  },
  props: {
    imageHost: {
      type: String,
      default() {
        return '';
      },
    },
    drones: {
      type: Array,
      default() {
        return [];
      },
    },
    drone1: {
      type: Object,
      default() {
        return {};
      },
    },
    drone2: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    drone1(newVal) { // watch it
      this.selected1 = newVal.slug;
    },
    drone2(newVal) { // watch it
      this.selected2 = newVal.slug;
    },
  },
  methods: {
    // TODO dont think this should have to reolad the page. Should just pass in data on the fly?
    onChange(drone1sel, drone2sel) {
      this.$router.push(`/compare/${drone1sel}-vs-${drone2sel}`);
      // window.location.href = `/compare/${drone1sel}-vs-${drone2sel}`;
    },
  },
  computed: {
    // This filters out the drone they have selected to compare so you cant compare the same one
    // TODO Seems a bit hacky to me that Id need to do this twice?
    firstSelectedDrones() {
      return this.drones.filter((drone) => drone.slug !== this.drone2.slug);
    },
    secondSelectedDrones() {
      return this.drones.filter((drone) => drone.slug !== this.drone1.slug);
    },
  },
};
</script>

<style>

body {
  background:#fff;
}

.table-striped tbody tr:first-child {
  background-color:transparent;
  border-top:none;
}

.table-striped tbody tr:first-child td, .table-striped tbody tr:first-child th{
  border-top:none;
}

</style>
