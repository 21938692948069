<template>
  <div>
    <b-modal
      id="modal-1"
      :title="selectedDrone.manufacturer + ' - ' + selectedDrone.model"
      size="lg"
      ok-only
      ok-title="Close"
      ok-variant="secondary"
    >
      <b-container class="bv-example-row">
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <img
              :src="imageHost + '/assets/images/drones/' +
                selectedDrone.image_base + '.large.jpg'"
            >
          </b-col>
          <b-col>
            <h3>Summary</h3>
            <p>{{ selectedDrone.summary }}</p>
            <b-button
              v-if="selectedDrone.has_affiliate"
              class="btn-block btn-primary my-3"
              :href="'/buy/'+ selectedDrone.id"
            >
              Check latest price
            </b-button>
            <b-button
              v-else
              class="btn-block btn-secondary my-2"
            >
              No buy link yet
            </b-button>
            <h3 class="mb-3">
              Key specs
            </h3>

            <p>
              <b-icon-calendar />
              Release date:
              <span v-if="selectedDrone.release_date">
                {{ selectedDrone.release_date }}
              </span>
              <span v-else>
                Unknown
              </span>
            </p>
            <p>
              <b-icon-battery-half />
              Flight time
              {{ selectedDrone.flight_time }}mins
            </p>
            <p>
              <b-icon-wifi />
              Range
              {{ selectedDrone.range | humanRange }}
            </p>
            <p>
              <b-icon-camera />
              Camera quality
              {{ selectedDrone.camera | humanCameraQuality }}
            </p>
            <p>
              <b-icon-camera-video />
              Video quality
              {{ selectedDrone.video_quality | humanVideoQuality }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3>Review and flight test</h3>
            <div class="videoWrapper">
              <iframe
                :src="'https://www.youtube.com/embed/' +
                  selectedDrone.youtube_id + '?start=' +
                  selectedDrone.youtube_time"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedDrone: {
      type: Object,
      default() {
        return {};
      },
    },
    imageHost: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>
