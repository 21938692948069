<template>
  <div>
    <div class="container article-detail mt-4">
      <div class="row">
        <div class="col-12 col-lg-8 mx-auto">
          <VueShowdown :markdown="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Markdown',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  metaInfo() {
    return {
      // TODO: fix title
      title: 'Articles',
    };
  },
};

</script>

<style>
.article-detail img{
  max-width: 100%;
}
</style>
