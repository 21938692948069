<template>
  <div id="app">
    <b-navbar
      toggleable="md"
      class="mt-2 mb-5 py-2"
    >
      <b-navbar-brand>
        <b-link
          href="/"
          class="text-dark"
        >
          <b-img
            src="/assets/images/logo-icon.png"
            alt="Responsive image"
            width="36"
          />
          <strong class="ml-2">Dronesifter</strong>
        </b-link>
      </b-navbar-brand>
      <b-navbar-toggle
        target="nav-text-collapse"
      />
      <b-collapse
        id="nav-text-collapse"
        is-nav
      >
        <b-navbar-nav>
          <b-nav-item to="/">
            Home
          </b-nav-item>
          <b-nav-item to="/articles">
            Articles
          </b-nav-item>
          <b-nav-item to="/glossary">
            Glossary
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
          class="ml-auto"
        >
          <b-nav-item to="/about">
            About
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
    <b-row>
      <CookieConsent />
    </b-row>
  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    CookieConsent,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Drone comparison tool for beginners',
    // all titles will be injected into this template
    titleTemplate: '%s | Dronesifter',
  },
  mounted() {
    document.body.classList.add('body-padding');
  },
};
</script>

<style lang="scss">

.navbar-light .navbar-nav .nav-link {
  font-weight: bold;
}

.Cookie--dark-lime .Cookie__button {
  background: #00b289;
}

@media (min-width: 768px) {
  body-padding {
    padding: 1em;
  }
}
</style>
