<template>
  <footer>
    <cookie-law
      theme="dark-lime"
      @accept="EnableCookies()"
    >
      <div slot="message">
        We use cookies to analyze site traffic and to ensure
        that we give you the best experience on our website.
        <router-link to="privacy-policy">
          Find out more
        </router-link>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import { bootstrap } from 'vue-gtag';
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
  methods: {
    EnableCookies() {
      // eslint-disable-next-line no-unused-vars
      bootstrap().then((gtag) => {});
    },
  },
};
</script>
