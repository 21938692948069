/* eslint-disable */
import axios from 'axios';

const apiUrl = process.env.VUE_APP_DRONEDATA_API_HOST;

const apiClient = axios.create({
    baseURL: apiUrl,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type' : 'application/json'
    }
})

export default {
    getDrones(){
        return apiClient.get('/drones.json')
    },
}
